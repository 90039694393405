import React, { Fragment } from 'react';
import { Col, Heading, Row } from '@fast-ai/ui-components';
import { Link } from 'gatsby-theme-fast-ai-sidebar';
import VisuallyHidden from '@fast-ai/ui/VisuallyHidden/VisuallyHidden';
import { H1 } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';

import { BlogTopicLink, Section, Seo } from '../ui';
import { DefaultLayout } from '../layout';
import ArticlePreview from '../ui/ArticlePreview';
import { RichFormattedMessage } from '../intl';
import m from '../intl/messages/pageBlog';
import { genLocalizedTopicLink } from '../ui/BlogTopicLink';

const NON_BREAKING_SPACE = ' ';
const BREAKING_SPACE = ' ';
const toNonBreakingWord = x => x?.replace(BREAKING_SPACE, NON_BREAKING_SPACE);
const BLOG_TITLE = 'Blog';
const BLOG_DESCRIPTION = 'Blog';

const styles = { heroRow: { flexWrap: 'wrap' } };
const convertPropsToPost = ({
	node: {
		id,
		timeToRead,
		frontmatter: {
			title,
			subtitle,
			description,
			topics,
			author: { image: authorImage, fullName: author },
			published,
			thumbnail,
		},
		fields: { slug },
	},
}) => ({
	id,
	to: `${slug}`,
	authorImage,
	author,
	topics,
	published,
	timeToRead,
	title,
	subtitle,
	description,
	thumbnail,
});

const BlogListPage = ({
	pageContext: { currentTopicSlug, language: currentLanguage },
	data,
	...rest
}) => {
	const {
		allTopicsJson: { edges: allTopicsData },
		allPosts: { edges: postsData },
		featuredPosts: { edges: featuredPostsData },
	} = data;

	const currentTopic = allTopicsData?.find(
		x => x.node.slug === currentTopicSlug
	)?.node;

	const { name: currentTopicName } =
		genLocalizedTopicLink({
			currentLanguage,
			topicData: currentTopic,
		}) || {};

	const posts = postsData.map(convertPropsToPost);
	const featuredPosts = featuredPostsData.map(convertPropsToPost);

	let hero;
	const suffix = currentTopicName && (
		<Fragment>
			{' '}
			<RichFormattedMessage
				{...m.section}
				values={{
					section: toNonBreakingWord(currentTopicName),
				}}
			/>
		</Fragment>
	);
	if (posts.length > 2) {
		const [main, post1, post2] = featuredPosts;
		hero = (
			<Section
				styles={{ container: { py: [0, 0, 0, 0] } }}
				variant="stripesPrimary"
			>
				<H1 variant="subHeading3" sx={{ textAlign: 'left' }}>
					<RichFormattedMessage {...m.heading} />
					{suffix}
				</H1>
				<Row sx={styles.heroRow}>
					<Col as="article" span={[12, 12, 8, 8]}>
						<ArticlePreview
							layout="vertical-start"
							size="large"
							headingLevel={3}
							{...main}
							topics={null}
						/>
					</Col>
					<Col span={[12, 12, 4, 4]}>
						<Row sx={styles.heroRow}>
							<Col as="article" span={[6, 6, 12, 12]}>
								<ArticlePreview
									headingLevel={3}
									layout="vertical-start"
									hidePerex
									{...post1}
									timeToRead={null}
									topics={null}
									author={null}
									authorImage={null}
								/>
							</Col>
							<Col as="article" span={[6, 6, 12, 12]}>
								<ArticlePreview
									headingLevel={3}
									layout="vertical-start"
									hidePerex
									{...post2}
									timeToRead={null}
									topics={null}
									author={null}
									authorImage={null}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Section>
		);
	}
	const title = currentTopicName ? currentTopicName : BLOG_TITLE;

	return (
		<DefaultLayout {...rest}>
			<Seo
				description={
					currentTopicName ? currentTopic?.description : BLOG_DESCRIPTION
				}
				title={title}
			/>
			<main>
				<VisuallyHidden>
					<Heading>{title}</Heading>
				</VisuallyHidden>
				{hero}
				<Section variant={hero ? 'stripesSecondary' : 'stripesPrimary'}>
					<Heading as="h2" variant="subHeading1">
						<RichFormattedMessage {...m.recentArticles} />
						{suffix}
					</Heading>
					{allTopicsData && (
						<Row
							as="nav"
							sx={{
								py: [2],
								mt: [2],
								mb: [3, 3, 4],
								borderBottom: t => t.borders.normal,
							}}
						>
							<Col span={12}>
								<BlogTopicLink as={Link} to="/blog" active={!currentTopicName}>
									<RichFormattedMessage {...m.allTopics} />
								</BlogTopicLink>
								{allTopicsData.map(({ node: topicData }) => (
									<BlogTopicLink
										key={topicData.slug}
										topicData={topicData}
										currentTopicSlug={currentTopicSlug}
									/>
								))}
							</Col>
						</Row>
					)}

					{posts.map(props => (
						<ArticlePreview
							headingLevel={3}
							as="article"
							key={props.id}
							layout="horizontal-start"
							{...props}
							topics={props.topics.map(topicData =>
								genLocalizedTopicLink({ currentLanguage, topicData })
							)}
						/>
					))}
				</Section>
			</main>
		</DefaultLayout>
	);
};
export default BlogListPage;
