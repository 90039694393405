import { graphql } from 'gatsby';

import BlogListPage from '../templates/BlogListPage';

// INSPIRATION
// - https://dribbble.com/shots/18841111-Blog-Website-Design
//
// TODO: refactor frontmatter heading
// Nice to haves:
// - filtering by topic
// - pagination
//
/* eslint-disable react/prop-types,react/display-name */

export const pageQuery = graphql`
	query BlogCategoryPageQuery($language: String, $currentTopicSlug: String) {
		allTopicsJson {
			edges {
				node {
					name
					slug
					description
					lang
					alternates {
						slug
						lang
						name
						description
					}
				}
			}
		}
		featuredPosts: allMdx(
			limit: 3
			sort: {
				fields: [frontmatter___featuredPost, frontmatter___published]
				order: [ASC, DESC]
			}
			filter: {
				frontmatter: {
					topics: { elemMatch: { slug: { eq: $currentTopicSlug } } }
					lang: { in: [$language] }
				}
				fields: { source: { eq: "blog" } }
			}
		) {
			edges {
				node {
					...BlogNode
				}
			}
		}
		allPosts: allMdx(
			sort: { fields: [frontmatter___published], order: DESC }
			filter: {
				frontmatter: {
					topics: { elemMatch: { slug: { eq: $currentTopicSlug } } }
					lang: { in: [$language] }
				}
				fields: { source: { eq: "blog" } }
			}
		) {
			edges {
				node {
					...BlogNode
				}
			}
		}
	}
`;

export default BlogListPage;
