import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	title: {
		id: 'page.blog.title',
		defaultMessage: 'Blog',
	},
	heading: {
		id: 'page.blog.heading',
		defaultMessage: 'Featured articles',
	},
	recentArticles: {
		id: 'page.blog.recentArticles',
		defaultMessage: 'Recent articles',
	},
	section: {
		id: 'page.blog.section',
		defaultMessage: 'in "{section}"',
	},
	allTopics: {
		id: 'page.blog.allTopics',
		defaultMessage: 'All',
	},
});
