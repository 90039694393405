import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@fast-ai/ui-components';

/**
 * Hide only visually, but have it available for screen readers:
 *
 * @see https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
const VisuallyHidden = forwardRef(({ sx, isFocusable, ...rest }, ref) => (
	<Box
		aria-hidden="true"
		sx={{
			border: '0',
			clip: 'rect(0 0 0 0)',
			height: '1px',
			margin: '-1px',
			overflow: 'hidden',
			padding: '0',
			width: '1px',
			whiteSpace: 'nowrap',
			...(isFocusable
				? {
						clip: 'auto',
						height: 'auto',
						margin: '0',
						overflow: 'visible',
						position: 'static',
						width: 'auto',
						whiteSpace: 'inherit',
				  }
				: {}),
			...sx,
		}}
		{...rest}
		ref={ref}
	/>
));

VisuallyHidden.displayName = 'VisuallyHidden';
VisuallyHidden.propTypes = {
	/**
	 * To allow the element to be focusable when navigated to via the keyboard:
	 */
	isFocusable: PropTypes.bool,
};

export default VisuallyHidden;
